import * as React from "react";
import Seo from "@components/seo";
import Layout from "@layout";
import Header from "@layout/header/header-one";
import Footer from "@layout/footer/footer-one";
import PageHeader from "@components/pageheader";
import ContactFormArea from '@containers/contact-us/contact-form-area'
import slide1 from "@assets/images/survey/1/FB_IMG_1581827013923.jpg"
import slide2 from "@assets/images/survey/1/FB_IMG_1581827112402.jpg"
import slide3 from "@assets/images/survey/1/FB_IMG_1625727814122.jpg"

import tab121 from "@assets/images/survey/2/FB_IMG_1600617381396.jpg"
import tab122 from "@assets/images/survey/2/FB_IMG_1625727744204.jpg"
import tab123 from "@assets/images/survey/2/FB_IMG_1625727782971.jpg"

import tab133 from "@assets/images/survey/3/FB_IMG_1625727697316.jpg"
import tab131 from "@assets/images/survey/3/FB_IMG_1625727809536.jpg"
import tab132 from "@assets/images/survey/3/FB_IMG_1627105090485.jpg"

import tab143 from "@assets/images/survey/4/FB_IMG_1512530830403.jpg"
import tab141 from "@assets/images/survey/4/FB_IMG_1612517177246.jpg"
import tab142 from "@assets/images/survey/4/FB_IMG_1612517212357.jpg"

import tab153 from "@assets/images/survey/5/FB_IMG_1627275040674.jpg"
import tab151 from "@assets/images/survey/5/FB_IMG_1627275044364.jpg"
import tab152 from "@assets/images/survey/5/FB_IMG_1627275182447.jpg"

import tab163 from "@assets/images/survey/6/FB_IMG_1625831359827.jpg"
import tab161 from "@assets/images/survey/6/FB_IMG_1627275128576.jpg"
import tab162 from "@assets/images/survey/6/FB_IMG_1627275151258.jpg"

import tab173 from "@assets/images/survey/7/FB_IMG_1621359658326.jpg"
import tab171 from "@assets/images/survey/7/FB_IMG_1621359713013.jpg"
import tab172 from "@assets/images/survey/7/FB_IMG_1625727635983.jpg"

import tab183 from "@assets/images/survey/8/FB_IMG_1484927902451.jpg"
import tab181 from "@assets/images/survey/8/FB_IMG_1621359661852.jpg"
import tab182 from "@assets/images/survey/8/FB_IMG_1621359667674.jpg"

import tab193 from "@assets/images/survey/9/FB_IMG_1514751121586.jpg"
import tab191 from "@assets/images/survey/9/FB_IMG_1626368471424.jpg"
import tab192 from "@assets/images/survey/9/FB_IMG_1626593369062.jpg"

import tab1103 from "@assets/images/survey/10/FB_IMG_1621376460386.jpg"
import tab1101 from "@assets/images/survey/10/FB_IMG_1626368528516.jpg"
import tab1102 from "@assets/images/survey/10/FB_IMG_1626593455646.jpg"

import tab1113 from "@assets/images/survey/11/FB_IMG_1626596787601.jpg"
import tab1111 from "@assets/images/survey/11/FB_IMG_1627026872323.jpg"
import tab1112 from "@assets/images/survey/11/FB_IMG_1627279023793.jpg"
import tab1114 from "@assets/images/survey/11/FB_IMG_1627279023793.jpg"

import tab211 from "@assets/images/survey/new project/1/FB_IMG_1626596787601.jpg"
import tab212 from "@assets/images/survey/new project/1/FB_IMG_1627026872323.jpg"
import tab213 from "@assets/images/survey/new project/1/FB_IMG_1627279023793.jpg"
import tab214 from "@assets/images/survey/new project/1/FB_IMG_1627308104856.jpg"

import tab221 from "@assets/images/survey/new project/2/FB_IMG_1519834036325.jpg"
import tab222 from "@assets/images/survey/new project/2/FB_IMG_1588982878017.jpg"
import tab223 from "@assets/images/survey/new project/2/FB_IMG_1588982881859.jpg"

import tab231 from "@assets/images/survey/new project/3/FB_IMG_1588982892819.jpg"
import tab232 from "@assets/images/survey/new project/3/FB_IMG_1588982897336.jpg"
import tab233 from "@assets/images/survey/new project/3/FB_IMG_1588982958212.jpg"


import tab241 from "@assets/images/survey/new project/4/FB_IMG_1621359658326.jpg"
import tab242 from "@assets/images/survey/new project/4/FB_IMG_1621359713013.jpg"
import tab243 from "@assets/images/survey/new project/4/FB_IMG_1625727635983.jpg"

import tab251 from "@assets/images/survey/new project/5/FB_IMG_1478909084073.jpg"
import tab252 from "@assets/images/survey/new project/5/FB_IMG_1478909145658.jpg"
import tab253 from "@assets/images/survey/new project/5/FB_IMG_1482695600506.jpg"

import tab261 from "@assets/images/survey/new project/6/FB_IMG_1499962578015.jpg"
import tab262 from "@assets/images/survey/new project/6/FB_IMG_1500970428262.jpg"
import tab263 from "@assets/images/survey/new project/6/FB_IMG_1625760911681.jpg"

import tab271 from "@assets/images/survey/new project/7/FB_IMG_1479984945465.jpg"
import tab272 from "@assets/images/survey/new project/7/FB_IMG_1479985143772.jpg"
import tab273 from "@assets/images/survey/new project/7/FB_IMG_1625856558408.jpg"

import tab281 from "@assets/images/survey/new project/8/FB_IMG_1478909137125.jpg"
import tab282 from "@assets/images/survey/new project/8/FB_IMG_1514751164423.jpg"
import tab283 from "@assets/images/survey/new project/8/FB_IMG_1625727850423.jpg"

import tab291 from "@assets/images/survey/new project/9/2-1.jpg"
import tab292 from "@assets/images/survey/new project/9/FB_IMG_1478909127169.jpg"
import tab293 from "@assets/images/survey/new project/9/FB_IMG_1492622364950.jpg"
import tab294 from "@assets/images/survey/new project/9/FB_IMG_1509654299217.jpg"

import tab2101 from "@assets/images/survey/new project/10/FB_IMG_1510508971846.jpg"
import tab2102 from "@assets/images/survey/new project/10/FB_IMG_1512530914301.jpg"
import tab2103 from "@assets/images/survey/new project/10/FB_IMG_1515245411281.jpg"


import tab2111 from "@assets/images/survey/new project/11/FB_IMG_1493533433124.jpg"
import tab2112 from "@assets/images/survey/new project/11/FB_IMG_1610915936811.jpg"
import tab2113 from "@assets/images/survey/new project/11/FB_IMG_1625727631346.jpg"

import tab2121 from "@assets/images/survey/new project/12/FB_IMG_1514751121586.jpg"
import tab2122 from "@assets/images/survey/new project/12/FB_IMG_1626368471424.jpg"
import tab2123 from "@assets/images/survey/new project/12/FB_IMG_1626593369062.jpg"

import tab311 from "@assets/images/survey/modify project/1/FB_IMG_1588982906332.jpg"
import tab312 from "@assets/images/survey/modify project/1/FB_IMG_1588982988055.jpg"
import tab313 from "@assets/images/survey/modify project/1/FB_IMG_1627277612959.jpg"

import tab321 from "@assets/images/survey/modify project/2/FB_IMG_1484927902451.jpg"
import tab322 from "@assets/images/survey/modify project/2/FB_IMG_1484927902451.jpg"
import tab323 from "@assets/images/survey/modify project/2/FB_IMG_1484927902451.jpg"

import tab331 from "@assets/images/survey/modify project/3/FB_IMG_1627026790910.jpg"
import tab332 from "@assets/images/survey/modify project/3/FB_IMG_1627026806640.jpg"
import tab333 from "@assets/images/survey/modify project/3/FB_IMG_1627026867184.jpg"

import tab341 from "@assets/images/survey/modify project/4/FB_IMG_1492094674622.jpg"
import tab342 from "@assets/images/survey/modify project/4/FB_IMG_1496796494532.jpg"
import tab343 from "@assets/images/survey/modify project/4/FB_IMG_1510508988680.jpg"
import tab344 from "@assets/images/survey/modify project/4/FB_IMG_1511066793906.jpg"

import tab351 from "@assets/images/survey/modify project/5/FB_IMG_1625727713800.jpg"
import tab352 from "@assets/images/survey/modify project/5/FB_IMG_1627104978787.jpg"
import tab353 from "@assets/images/survey/modify project/5/FB_IMG_1627105006796.jpg"

import tab361 from "@assets/images/survey/modify project/6/FB_IMG_1585201657042.jpg"
import tab362 from "@assets/images/survey/modify project/6/FB_IMG_1591635012850.jpg"
import tab363 from "@assets/images/survey/modify project/6/FB_IMG_1596892538728.jpg"

import tab371 from "@assets/images/survey/modify project/7/FB_IMG_1596892579786.jpg"
import tab372 from "@assets/images/survey/modify project/7/FB_IMG_1625763529977.jpg"
import tab373 from "@assets/images/survey/modify project/7/Screenshot_20180221-230718.png"

import tab381 from "@assets/images/survey/modify project/8/FB_IMG_1515436588480.jpg"
import tab382 from "@assets/images/survey/modify project/8/FB_IMG_1515436588480.jpg"
import tab383 from "@assets/images/survey/modify project/8/FB_IMG_1515436588480.jpg"

import tab391 from "@assets/images/survey/modify project/9/FB_IMG_1581827096391.jpg"
import tab392 from "@assets/images/survey/modify project/9/FB_IMG_1610916002277.jpg"
import tab393 from "@assets/images/survey/modify project/9/FB_IMG_1625727657808.jpg"

import tab3101 from "@assets/images/survey/modify project/10/FB_IMG_1621376460386.jpg"
import tab3102 from "@assets/images/survey/modify project/10/FB_IMG_1626368528516.jpg"
import tab3103 from "@assets/images/survey/modify project/10/FB_IMG_1626593455646.jpg"

//fourth step
import tab421 from "@assets/images/survey/landscaping/2/FB_IMG_1519834036325.jpg"
import tab422 from "@assets/images/survey/landscaping/2/FB_IMG_1588982878017.jpg"
import tab423 from "@assets/images/survey/landscaping/2/FB_IMG_1588982881859.jpg"

import tab431 from "@assets/images/survey/landscaping/3/FB_IMG_1588982892819.jpg"
import tab432 from "@assets/images/survey/landscaping/3/FB_IMG_1588982897336.jpg"
import tab433 from "@assets/images/survey/landscaping/3/FB_IMG_1588982958212.jpg"

import tab451 from "@assets/images/survey/landscaping/5/FB_IMG_1478909084073.jpg"
import tab452 from "@assets/images/survey/landscaping/5/FB_IMG_1478909145658.jpg"
import tab453 from "@assets/images/survey/landscaping/5/FB_IMG_1482695600506.jpg"

import tab491 from "@assets/images/survey/landscaping/9/2-1.jpg"
import tab492 from "@assets/images/survey/landscaping/9/FB_IMG_1478909127169.jpg"
import tab493 from "@assets/images/survey/landscaping/9/FB_IMG_1492622364950.jpg"
import tab494 from "@assets/images/survey/landscaping/9/FB_IMG_1509654299217.jpg"

import tab4101 from "@assets/images/survey/landscaping/10/FB_IMG_1510508971846.jpg"
import tab4102 from "@assets/images/survey/landscaping/10/FB_IMG_1512530914301.jpg"
import tab4103 from "@assets/images/survey/landscaping/10/FB_IMG_1515245411281.jpg"

const stepImages = [
  [
    { value: slide1, index: 1, secondIndex:1, name: "image11",checked:false ,tab:"Interior Design",stepNumber:1},
    { value: slide2, index: 2, secondIndex:2, name: "image12",checked:false ,tab:"Interior Design",stepNumber:1 },
    { value: slide3, index: 3, secondIndex:3, name: "image13",checked:false,tab:"Interior Design",stepNumber:1 },
  ],
  [
    { value: tab121, index: 4, secondIndex:1, name: "tab121",checked:false,tab:"Interior Design",stepNumber:2 },
    { value: tab122, index: 5,secondIndex:2, name: "tab122",checked:false,tab:"Interior Design",stepNumber:2 },
    { value: tab123, index: 6,secondIndex:3, name: "tab123",checked:false,tab:"Interior Design",stepNumber:2 },
  ],
  [
    { value: tab131, index: 7,secondIndex:1, name: "tab131" ,checked:false,tab:"Interior Design",stepNumber:3},
    { value: tab132, index: 8,secondIndex:2, name: "tab132" ,checked:false,tab:"Interior Design",stepNumber:3},
    { value: tab133, index: 9,secondIndex:3, name: "tab133",checked:false,tab:"Interior Design",stepNumber:3 },
  ],
  [
    { value: tab141, index: 10, secondIndex:1, name: "tab141" ,checked:false,tab:"Interior Design",stepNumber:4},
    { value: tab142, index: 11, secondIndex:2, name: "tab142",checked:false,tab:"Interior Design",stepNumber:4 },
    { value: tab143, index: 12, secondIndex:3, name: "tab143",checked:false,tab:"Interior Design",stepNumber:4 },
  ],
  [
    { value: tab151, index: 13, secondIndex:1, name: "tab151", checked:false,tab:"Interior Design",stepNumber:5},
    { value: tab152, index: 14, secondIndex:2, name: "tab152",checked:false,tab:"Interior Design",stepNumber:5 },
    { value: tab153, index: 15, secondIndex:3, name: "tab153",checked:false,tab:"Interior Design",stepNumber:5 },
  ],
  [
    { value: tab161, index: 16,secondIndex:3, name: "tab123",checked:false,tab:"Interior Design",stepNumber:6, name: "tab161" },
    { value: tab162, index: 17, secondIndex:3, name: "tab123",checked:false,tab:"Interior Design",stepNumber:6, name: "tab162" },
    { value: tab163, index: 18, secondIndex:3, name: "tab123",checked:false,tab:"Interior Design",stepNumber:6, name: "tab163" },
  ],
  [
    { value: tab171, index: 19, secondIndex:3,checked:false,tab:"Interior Design",stepNumber:7, name: "tab171" },
    { value: tab172, index: 20, secondIndex:3,checked:false,tab:"Interior Design",stepNumber:7, name: "tab172" },
    { value: tab173, index: 21, secondIndex:3,checked:false,tab:"Interior Design",stepNumber:7, name: "tab173" },
  ],
  [
    { value: tab181, index: 1,secondIndex:3, name: "tab181",checked:false,tab:"Interior Design",stepNumber:8, name: "tab181" },
    { value: tab182, index: 2,secondIndex:3, name: "tab182",checked:false,tab:"Interior Design",stepNumber:8, name: "tab182" },
    { value: tab183, index: 3, secondIndex:3, name: "tab183",checked:false,tab:"Interior Design",stepNumber:8, name: "tab183" },
  ],
  [
    { value: tab191, index: 1, secondIndex:3, name: "tab193",checked:false,tab:"Interior Design",stepNumber:9, name: "tab191" },
    { value: tab192, index: 2, secondIndex:3, name: "tab193",checked:false,tab:"Interior Design",stepNumber:9, name: "tab192" },
    { value: tab193, index: 3, secondIndex:3, name: "tab193",checked:false,tab:"Interior Design",stepNumber:9, name: "tab193" },
  ],
  [
    { value: tab1101, index: 1, secondIndex:3, name: "tab1103",checked:false,tab:"Interior Design",stepNumber:10, name: "tab1101" },
    { value: tab1102, index: 2, secondIndex:3, name: "tab1103",checked:false,tab:"Interior Design",stepNumber:10, name: "tab1102" },
    { value: tab1103, index: 3,secondIndex:3, name: "tab1103",checked:false,tab:"Interior Design",stepNumber:10, name: "tab1103" },
  ],
 
  [
    { value: tab1111, index: 1, name: "tab1111", secondIndex:3, name: "tab123",checked:false,tab:"Interior Design",stepNumber:11 },
    { value: tab1112, index: 2, name: "tab1112",secondIndex:3, name: "tab123",checked:false,tab:"Interior Design",stepNumber:11 },
    { value: tab1113, index: 3, name: "tab1113",secondIndex:3, name: "tab123",checked:false,tab:"Interior Design",stepNumber:11 },
    { value: tab1114, index: 4, name: "tab1113" ,secondIndex:3, name: "tab123",checked:false,tab:"Interior Design",stepNumber:11},
  ],
// second tab
[
{ value: tab211, index: 35, name: "tab211", secondIndex:1,checked:false,tab:"New Design",stepNumber:1 },
{ value: tab212, index: 36, name: "tab222",secondIndex:2,checked:false,tab:"New project",stepNumber:1 },
{ value: tab213, index: 37, name: "tab223",secondIndex:3,checked:false,tab:"New Design",stepNumber:1 },
{ value: tab214, index: 38, name: "tab224",secondIndex:4,checked:false,tab:"New Design",stepNumber:1},

],
[
{ value: tab221, index: 39, name: "tab221", secondIndex:1,checked:false,tab:"New Design",stepNumber:2 },
{ value: tab222, index: 40, name: "tab222",secondIndex:2,checked:false,tab:"New project",stepNumber:2 },
{ value: tab223, index: 41, name: "tab223",secondIndex:3,checked:false,tab:"New Design",stepNumber:2 },


],
[
{ value: tab231, index: 42, name: "tab221", secondIndex:1,checked:false,tab:"New Design",stepNumber:3 },
{ value: tab232, index: 43, name: "tab222",secondIndex:2,checked:false,tab:"New project",stepNumber:3 },
{ value: tab233, index: 44, name: "tab223",secondIndex:3,checked:false,tab:"New Design",stepNumber:3 
},


],
[
{ value: tab241, index: 45, name: "tab221", secondIndex:1,checked:false,tab:"New Design",stepNumber:4 },
{ value: tab242, index: 46, name: "tab222",secondIndex:2,checked:false,tab:"New project",stepNumber:4 },
{ value: tab243, index: 47, name: "tab223",secondIndex:3,checked:false,tab:"New Design",stepNumber:4 },


],
[
{ value: tab251, index: 39, name: "tab221", secondIndex:1,checked:false,tab:"New Design",stepNumber:5 },
{ value: tab252, index: 40, name: "tab222",secondIndex:2,checked:false,tab:"New project",stepNumber:5 },
{ value: tab253, index: 41, name: "tab223",secondIndex:3,checked:false,tab:"New Design",stepNumber:5 },


],
[
{ value: tab261, index: 39, name: "tab221", secondIndex:1,checked:false,tab:"New Design",stepNumber:6 },
{ value: tab262, index: 40, name: "tab222",secondIndex:2,checked:false,tab:"New project",stepNumber:6 },
{ value: tab263, index: 41, name: "tab223",secondIndex:3,checked:false,tab:"New Design",stepNumber:6 },


],
[
{ value: tab271, index: 39, name: "tab221", secondIndex:1,checked:false,tab:"New Design",stepNumber:7 },
{ value: tab272, index: 40, name: "tab222",secondIndex:2,checked:false,tab:"New project",stepNumber:7 },
{ value: tab273, index: 41, name: "tab223",secondIndex:3,checked:false,tab:"New Design",stepNumber:7 },


],
[
{ value: tab281, index: 39, name: "tab221", secondIndex:1,checked:false,tab:"New Design",stepNumber:8 },
{ value: tab282, index: 40, name: "tab222",secondIndex:2,checked:false,tab:"New project",stepNumber:8 },
{ value: tab283, index: 41, name: "tab223",secondIndex:3,checked:false,tab:"New Design",stepNumber:8 },


],
[
{ value: tab291, index: 39, name: "tab221", secondIndex:1,checked:false,tab:"New Design",stepNumber:9 },
{ value: tab292, index: 40, name: "tab222",secondIndex:2,checked:false,tab:"New project",stepNumber:9 },
{ value: tab293, index: 41, name: "tab223",secondIndex:3,checked:false,tab:"New Design",stepNumber:9 },
{ value: tab294, index: 41, name: "tab223",secondIndex:3,checked:false,tab:"New Design",stepNumber:9 },


],
[
{ value: tab2101, index: 39, name: "tab221", secondIndex:1,checked:false,tab:"New Design",stepNumber:10 },
{ value: tab2102, index: 40, name: "tab222",secondIndex:2,checked:false,tab:"New project",stepNumber:10 },
{ value: tab2103, index: 41, name: "tab223",secondIndex:3,checked:false,tab:"New Design",stepNumber:10 },


],
[
{ value: tab2111, index: 39, name: "tab221", secondIndex:1,checked:false,tab:"New Design",stepNumber:11 },
{ value: tab2112, index: 40, name: "tab222",secondIndex:2,checked:false,tab:"New project",stepNumber:11 },
{ value: tab2113, index: 41, name: "tab223",secondIndex:3,checked:false,tab:"New Design",stepNumber:11 },


],
[
{ value: tab2121, index: 42, name: "tab221", secondIndex:1,checked:false,tab:"New Design",stepNumber:12 },
{ value: tab2122, index: 43, name: "tab222",secondIndex:2,checked:false,tab:"New project",stepNumber:12 },
{ value: tab2123, index: 44, name: "tab223",secondIndex:3,checked:false,tab:"New Design",stepNumber:12 },


],

//third tab
[
{ value: tab311, index: 42, name: "tab311", secondIndex:1,checked:false,tab:"Modify Project",stepNumber:1 },
{ value: tab312, index: 43, name: "tab312",secondIndex:2,checked:false,tab:"Modify Project",stepNumber:1 },
{ value: tab313, index: 44, name: "tab313",secondIndex:3,checked:false,tab:"Modify Project",stepNumber:1 },


],
[
{ value: tab321, index: 42, name: "tab321", secondIndex:1,checked:false,tab:"Modify Project",stepNumber:2 },
{ value: tab322, index: 43, name: "tab322",secondIndex:2,checked:false,tab:"Modify Project",stepNumber:2 },
{ value: tab323, index: 44, name: "tab323",secondIndex:3,checked:false,tab:"Modify Project",stepNumber:2 },


],
[
{ value: tab331, index: 39, name: "tab321", secondIndex:1,checked:false,tab:"Modify Project",stepNumber:3 },
{ value: tab332, index: 40, name: "tab332",secondIndex:2,checked:false,tab:"Modify Project",stepNumber:3 },
{ value: tab333, index: 41, name: "tab333",secondIndex:3,checked:false,tab:"Modify Project",stepNumber:3 },



],
[
{ value: tab341, index: 39, name: "tab341", secondIndex:1,checked:false,tab:"Modify Project",stepNumber:4 },
{ value: tab342, index: 40, name: "tab342",secondIndex:2,checked:false,tab:"Modify Project",stepNumber:4 },
{ value: tab343, index: 41, name: "tab343",secondIndex:3,checked:false,tab:"Modify Project",stepNumber:4 },
{ value: tab344, index: 41, name: "tab343",secondIndex:3,checked:false,tab:"Modify Project",stepNumber:4 },


],
[
{ value: tab351, index: 39, name: "tab351", secondIndex:1,checked:false,tab:"Modify Project",stepNumber:5 },
{ value: tab352, index: 40, name: "tab352",secondIndex:2,checked:false,tab:"Modify Project",stepNumber:5},
{ value: tab353, index: 41, name: "tab353",secondIndex:3,checked:false,tab:"Modify Project",stepNumber:5 },



],
[
{ value: tab361, index: 39, name: "tab361", secondIndex:1,checked:false,tab:"Modify Project",stepNumber:6 },
{ value: tab362, index: 40, name: "tab362",secondIndex:2,checked:false,tab:"Modify Project",stepNumber:6 },
{ value: tab363, index: 41, name: "tab363",secondIndex:3,checked:false,tab:"Modify Project",stepNumber:6 },



],
[
{ value: tab371, index: 39, name: "tab371", secondIndex:1,checked:false,tab:"Modify Project",stepNumber:7 },
{ value: tab372, index: 40, name: "tab372",secondIndex:2,checked:false,tab:"Modify Project",stepNumber:7 },
{ value: tab373, index: 41, name: "tab373",secondIndex:3,checked:false,tab:"Modify Project",stepNumber:7 },


],
[
{ value: tab381, index: 39, name: "tab381", secondIndex:1,checked:false,tab:"Modify Project",stepNumber:8 },
{ value: tab382, index: 40, name: "tab382",secondIndex:2,checked:false,tab:"Modify Project",stepNumber:8 },
{ value: tab383, index: 41, name: "tab383",secondIndex:3,checked:false,tab:"Modify Project",stepNumber:8 },

],
[
{ value: tab391, index: 39, name: "tab391", secondIndex:1,checked:false,tab:"Modify Project",stepNumber:9 },
{ value: tab392, index: 40, name: "tab392",secondIndex:2,checked:false,tab:"Modify Project",stepNumber:9 },
{ value: tab393, index: 41, name: "tab393",secondIndex:3,checked:false,tab:"Modify Project",stepNumber:9 },



],
[
{ value: tab3101, index: 39, name: "tab491", secondIndex:1,checked:false,tab:"Modify Project",stepNumber:10},
{ value: tab3102, index: 40, name: "tab492",secondIndex:2,checked:false,tab:"Modify Project",stepNumber:10 },
{ value: tab3103, index: 41, name: "tab493",secondIndex:3,checked:false,tab:"Modify Project",stepNumber:10 },



],

//fourth step

[
  { value: tab421, index: 39, name: "tab421", secondIndex:1,checked:false,tab:"Garden Study",stepNumber:1 },
  { value: tab422, index: 40, name: "tab422",secondIndex:2,checked:false,tab:"Garden Study",stepNumber:1 },
  { value: tab423, index: 41, name: "tab423",secondIndex:3,checked:false,tab:"Garden Study",stepNumber:1 },
 

  
],
[
  { value: tab431, index: 39, name: "tab431", secondIndex:1,checked:false,tab:"Garden Study",stepNumber:2 },
  { value: tab432, index: 40, name: "tab432",secondIndex:2,checked:false,tab:"Garden Study",stepNumber:2 },
  { value: tab433, index: 41, name: "tab433",secondIndex:3,checked:false,tab:"Garden Study",stepNumber:2 },
 

  
],
[
  { value: tab451, index: 39, name: "tab451", secondIndex:1,checked:false,tab:"Garden Study",stepNumber:3 },
  { value: tab452, index: 40, name: "tab452",secondIndex:2,checked:false,tab:"Garden Study",stepNumber:3 },
  { value: tab453, index: 41, name: "tab453",secondIndex:3,checked:false,tab:"Garden Study",stepNumber:3 },
 

  
],
[
  { value: tab491, index: 39, name: "tab491", secondIndex:1,checked:false,tab:"Garden Study",stepNumber:4 },
  { value: tab492, index: 40, name: "tab492",secondIndex:2,checked:false,tab:"Garden Study",stepNumber:4 },
  { value: tab493, index: 41, name: "tab493",secondIndex:3,checked:false,tab:"Garden Study",stepNumber:4 },
  { value: tab494, index: 41, name: "tab494",secondIndex:4,checked:false,tab:"Garden Study",stepNumber:4 }
 

  
],
[
  { value: tab4101, index: 39, name: "tab4101", secondIndex:1,checked:false,tab:"New Design",stepNumber:5 },
  { value: tab4102, index: 40, name: "tab4102",secondIndex:2,checked:false,tab:"New project",stepNumber:5 },
  { value: tab4103, index: 41, name: "tab4103",secondIndex:3,checked:false,tab:"New Design",stepNumber:5 },
 

  
],
]
if (typeof window !== 'undefined') {
localStorage.setItem("wizarImages", JSON.stringify(stepImages))}
const ContactUsPage = ({ pageContext, location }) => (
	<Layout location={location}>
		<Seo title="Study request" />
		<Header />
		<PageHeader
			pageContext={pageContext}
			location={location}
			title="Contact Us"
			pageTitle="study_request"
		/>
		<main className="site-wrapper-reveal">
			<ContactFormArea formType="study-request" />
			
		</main>
		<Footer />
	</Layout>
)

export default ContactUsPage